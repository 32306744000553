<template>
    <Launcher
        id="cawi-chatbot"
        :class="getClasses"
        :participants="participants"
        :titleImageUrl="UI.header.logo.src"
        :onMessageWasSent="onMessageWasSent"
        :messageList="messages"
        :newMessagesCount="newMessagesCount"
        :isOpen="isChatOpen"
        :close="closeChat"
        :open="openChat"
        :showEmoji="false"
        :showFile="false"
        :showTypingIndicator="showTypingIndicator"
        :showLauncher="true"
        :showCloseButton="true"
        :colors="UI.colors"
        :alwaysScrollToBottom="true"
        :messageStyling="false"
        :placeholder="UI.chat.placeholder"
    >
        <template v-slot:header>
            <img :src="UI.header.logo.src" class="sc-header--img" />
            <div class="sc-header--title">{{ UI.header.title.text }}</div>
        </template>
    </Launcher>
</template>

<script>
import Vue from 'vue'
import Launcher from '@/lib/vue-beautiful-chat/src/Launcher'
import VTooltip from 'v-tooltip'
import store from './store'
import Vuex, { mapState, mapActions } from 'vuex'
import '@/styles.css'

Vue.use(VTooltip)

// Need to be declared here for production
if (process.env.NODE_ENV === 'production') Vue.use(Vuex)

export default {
    store,
    components: {
        Launcher
    },
    data() {
        return {
            newMessagesCount: 0,
            isChatOpen: false,
            showTypingIndicator: 'server',
            isNew: true
        }
    },
    computed: {
        ...mapState(['gkid', 'config', 'UI', 'messages', 'currentQuestion']),
        participants() {
            return [
                {
                    id: 'server',
                    name: this.UI.chat.server.name,
                    imageUrl: this.UI.chat.server.avatar
                },
                {
                    id: 'user',
                    name: this.UI.chat.user.name,
                    imageUrl: this.UI.chat.user.avatar
                }
            ]
        },
        getClasses() { // handle classes for suggestions display
            return {
                suggestions: this.messages.length && 'suggestions' in this.messages[this.messages.length - 1],
                multi: this.messages.length && 'suggestions' in this.messages[this.messages.length - 1] && this.messages[this.messages.length - 1].multi,
                loading: this.showTypingIndicator != ''
            }
        }
    },
    async created() {
        // init app
        this.isNew = await this.initApp()

        // depending on window width, auto open after 3 sec
        const mediaQuery = window.matchMedia('(min-width: 768px)')
        if (mediaQuery.matches) {
            setTimeout(async() => {
                this.openChat()
            }, 3000)
        }
    },
    methods: {
        ...mapActions(['initApp', 'getPage', 'nextPage', 'restartInterview']),
        // called when the user sends a message
        async onMessageWasSent(message) {
            this.$store.commit('pushMessage', message)

            this.showTypingIndicator = 'server'

            if (
                this.config.restartKeyword &&
                message.data.text.toUpperCase() === this.config.restartKeyword.toUpperCase()
            ) {
                await this.restartInterview()
            } else {
                // build response
                let value = message.data.text // default
                let response = {}
                if (this.currentQuestion.type === 'choice') {
                    if (this.currentQuestion.multi) {
                        // multi choice
                        const choices = []
                        value.split(',').forEach(val => {
                            choices.push(this.currentQuestion.modalities.find(m => m.label === val).code)
                        })
                        this.currentQuestion.modalities.find(m => m.label === message.data.text).code
                        response = { [this.currentQuestion.varName]: choices }
                    } else {
                        const mod = this.currentQuestion.modalities.filter(m => m.label === value)
                        if (mod.length) {
                            value = mod[0].code
                        }
                        response = { [this.currentQuestion.varName]: value }
                    }
                } else {
                    response = { [this.currentQuestion.varName]: value }
                }

                // send response and get next content
                await this.nextPage(response)
            }

            this.showTypingIndicator = ''
        },
        async openChat() {
            this.isChatOpen = true
            this.newMessagesCount = 0
            if (this.isNew) {
                await this.getPage()
                this.isNew = false
            }
            this.showTypingIndicator = ''
        },
        closeChat() {
            this.isChatOpen = false
        }
    }
}
</script>

<style>
.sc-message--text {
    white-space: pre-line;
}
.sc-user-input--text {
    font-size: 16px; /* fix iPhone issue: https://github.com/mattmezza/vue-beautiful-chat/issues/105 */
}
</style>
