/**
 * A questionnaire's page, with some properties and a list of contents (i.e questions or messages).
 */
class Page {
    constructor(iPage) {
        this.iPage = iPage
        this.isFirst = iPage === 0
        this.isLast = false
        this.contents = []
    }

    addContent(c) {
        this.contents.push(c)
    }
}

module.exports = Page
