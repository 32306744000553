import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import { v4 as uuid } from 'uuid'
import axios from 'axios'

axios.defaults.baseURL = process.env.VUE_APP_API_SERVER

let questionnaire = {} // no need to put in store

// Don't need to be declared here for production
if (process.env.NODE_ENV !== 'production') Vue.use(Vuex)

export default new Vuex.Store({
    strict: process.env.NODE_ENV !== 'production',

    state: {
        gkid: null,
        config: {},
        UI: {},
        messages: [],
        suggestions: {},
        currentPage: {}, // used to store last received page
        currentQuestion: {}, // used to store last asked question
        expiration: null // used to ignore local storage
    },

    mutations: {
        /**
         * Set the interview GKID into the store.
         */
        setGKID: (state, gkid) => {
            state.gkid = gkid
        },
        /**
         * Set the project's config into the store.
         */
        setConfig: (state, config) => {
            state.config = config
        },
        /**
         * Set the project's UI settings into the store.
         * Normalize them for easier usage in components.
         */
        setUI: (state, UI) => {
            const emptySettings = {
                header: { logo: {} },
                chat: {
                    server: {},
                    user: {}
                },
                colors: {}
            }

            try {
                const projectAssets = require.context(PROJECT_PATH, true, /assets\/.+\.(css|styl|png|jpg|svg)$/)
                ///projectAssets.keys().forEach(k => { console.log(k, projectAssets(k)) })

                // load styles
                if (UI.styles) {
                    for (let file of UI.styles) {
                        projectAssets(`./assets/${file}`)
                    }
                }
                // load logo
                if (UI.header && UI.header.logo) {
                    UI.header.logo.src = projectAssets(`./assets/${UI.header.logo.src}`)
                }
                // load avatars
                if (UI.chat) {
                    if (UI.chat.server.avatar) {
                        UI.chat.server.avatar = projectAssets(`./assets/${UI.chat.server.avatar}`)
                    }
                    if (UI.chat.user.avatar) {
                        UI.chat.user.avatar = projectAssets(`./assets/${UI.chat.user.avatar}`)
                    }
                }
            } catch (err) {
                console.error('UI ERROR:', err.message)
            }

            // merge empty and project settings
            state.UI = { ...emptySettings, ...UI } // NB: not recursive
        },
        /**
         * Set a page's definition into the store.
         */
        setPage: (state, page) => {
            state.currentPage = page
        },
        /**
         * Set current question's definition into the store.
         */
        setCurrentQuestion: (state, content) => {
            state.currentQuestion = content
        },
        /**
         * Push a message.
         * Called when chat user send a response.
         */
        pushMessage: (state, message) => {
            state.messages = [...state.messages, message]
        },
        /**
         * Clear messages list.
         * Called when chat user send restart keyword.
         */
        clearMessages: state => {
            state.messages = []
        },
        /**
         * ...
         * Used in src/lib/beautiful-chat-overrides/
         */
        setSuggestions: (state, suggestions) => {
            state.suggestions = suggestions
        },
        /**
         * Reset the store expiration.
         */
        resetExpiration: state => {
            state.expiration = new Date().getTime() + 24 * 60 * 60 * 1000 // 1 day
        }
    },

    actions: {
        /**
         * Get project's settings and questionnaire engine.
         * Get GKID from local storage or generate one if none.
         * @returns boolean Is new GKID ?
         */
        async initApp({ state, commit }) {
            // load project's settings
            let projectConfig = {}
            let projectUI = {}
            let projectSettings = {}
            try {
                projectSettings = require.context(PROJECT_PATH, true, /settings\/.+\.(js|json)$/)
                ///projectSettings.keys().forEach(k => { console.log(k, projectSettings(k)) })
                projectConfig = projectSettings('./settings/client_config.js')
                commit('setConfig', projectConfig)

                projectUI = projectSettings('./settings/ui.js')
                commit('setUI', projectUI)
            } catch (err) {
                console.error(err.message)
            }

            // build questionnaire (client-side engine)
            if (!projectConfig.cawiProxy) {
                let projectDefinitions = {}
                let projectRouting = []
                try {
                    projectDefinitions = projectSettings('./settings/definitions.json')
                    projectRouting = projectSettings('./settings/routing.json')
                } catch (err) {
                    console.error(err.message)
                }
                const Questionnaire = require('../../engine/src/questionnaire') // TODO: webpack alias ?

                questionnaire = new Questionnaire({
                    config: projectConfig,
                    definitions: projectDefinitions,
                    routing: projectRouting
                })
            }

            // clear storage if expired
            const now = new Date().getTime()
            if (now > state.expiration) {
                localStorage.removeItem(process.env.VUE_APP_PROJECT)

                // reset state
                commit('setGKID', null)
                commit('clearMessages')
                commit('setPage', {})
                commit('setCurrentQuestion', {})
                commit('resetExpiration')
            }

            // get GKID
            if (!state.gkid) {
                commit('setGKID', uuid())
                return true
            }
            return false
        },
        /**
         * Get existing interview then set current page's definition, using client-side engine or calling page API.
         * Called once, on chat creation.
         */
        async getPage({ state, commit, dispatch }) {
            let page = {}

            try {
                if (state.config.cawiProxy) {
                    // use server-side engine calling data/page API
                    const res = await axios.get(`/cawi/page/${process.env.VUE_APP_PROJECT}/${state.gkid}`)
                    if (res.data) {
                        page = res.data
                    }
                } else {
                    // use client-side engine to get page
                    page = await questionnaire.initInterview(state.gkid)
                }
            } catch (err) {
                console.error('getPage:', err.message)
            }

            commit('setPage', page)
            dispatch('displayContents', page)
        },
        /**
         * Save data then load next page's definition, using client-side engine or calling page API.
         * Called on each message submit.
         */
        async nextPage({ state, commit, dispatch }, data) {
            let nextPage = {}

            try {
                if (state.config.cawiProxy) {
                    if (state.currentPage.next) {
                        // should always exist for cawiProxy
                        data = { ...data, ...state.currentPage.next }
                    }
                    // use server-side engine calling data/page API
                    const res = await axios.post(`/cawi/page/${process.env.VUE_APP_PROJECT}/${state.gkid}/next`, data)
                    if (res.data) {
                        nextPage = res.data
                    }
                } else {
                    // use client-side engine to get page
                    nextPage = await questionnaire.nextPage(data)
                }
            } catch (err) {
                console.error('nextPage:', err.message)
            }

            commit('setPage', nextPage)
            await dispatch('displayContents', nextPage)
        },
        /**
         * Try to send data through and API call then load next page's definition.
         */
        async restartInterview({ state, commit, dispatch }) {
            commit('clearMessages')

            try {
                if (state.config.cawiProxy) {
                    const res = await axios.post(`/cawi/page/${process.env.VUE_APP_PROJECT}/${state.gkid}/restart`)
                    if (res.data) {
                        commit('setPage', res.data)
                        dispatch('displayContents', res.data)
                    }
                } else {
                    /// TODO
                }
            } catch (err) {
                console.error('restartInterview:', err.message)
            }
        },
        /**
         * Display page's contents as several chat messages.
         */
        async displayContents({ commit }, page) {
            if (!!page.contents) {
                let newMessages = []
                for (let content of page.contents) {
                    const chatMessage = { type: 'text', author: 'server', data: { text: null } }
                    const imgMessages = []
                    if (content.error) {
                        chatMessage.data.text = content.error
                        if (content.type === 'choice') {
                            chatMessage.suggestions = content.modalities.map(m => m.label)
                            chatMessage.multi = content.rules.maxChoices && content.rules.maxChoices > 1
                        }

                        // with error, ignore previous contents
                        newMessages = [chatMessage]
                        break
                    } else if (content.contentType === 'message') {
                        chatMessage.data.text = content.label
                    } else if (content.contentType === 'question') {
                        chatMessage.data.text = content.label
                        if (content.type === 'choice') {
                            for (const mod of content.modalities) {
                                if (mod.image) {
                                    const imgMessage = {
                                        type: 'file',
                                        author: 'server',
                                        data: { text: mod.label, file: { url: mod.image } }
                                    }
                                    imgMessages.push(imgMessage)
                                }
                            }
                            chatMessage.suggestions = content.modalities.map(m => m.label)
                            chatMessage.multi = content.rules.maxChoices && content.rules.maxChoices > 1
                        }
                        commit('setCurrentQuestion', content)
                    } else {
                        console.error('Unexpected content type')
                    }

                    chatMessage.isLast = content.isLast
                    newMessages.push(chatMessage)
                    if (imgMessages.length) {
                        imgMessages[imgMessages.length - 1].suggestions = chatMessage.suggestions
                        newMessages = [...newMessages, ...imgMessages]
                    }
                }

                for (let [i, message] of newMessages.entries()) {
                    commit('pushMessage', message)
                    if (i < newMessages.length - 1) {
                        await new Promise(resolve => setTimeout(resolve, 1000)) // wait 1 sec between each message
                    }
                }
            }
        }
    },

    plugins: [
        createPersistedState({
            key: process.env.VUE_APP_PROJECT,
            paths: ['gkid', 'messages', 'currentPage', 'currentQuestion', 'expiration']
        })
    ]
})
