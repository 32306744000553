<template>
    <div class="sc-chat-window" :class="{ opened: isOpen, closed: !isOpen }">
        <Header
            v-if="showHeader"
            :show-close-button="showCloseButton"
            :title="title"
            :image-url="titleImageUrl"
            :on-close="onClose"
            :colors="colors"
            :disable-user-list-toggle="disableUserListToggle"
            @userList="handleUserListToggle"
        >
            <template>
                <slot name="header"> </slot>
            </template>
        </Header>
        <UserList v-if="showUserList" :colors="colors" :participants="participants" />
        <MessageList
            v-if="!showUserList"
            :messages="messages"
            :participants="participants"
            :show-typing-indicator="showTypingIndicator"
            :colors="colors"
            :always-scroll-to-bottom="alwaysScrollToBottom"
            :show-edition="showEdition"
            :show-deletion="showDeletion"
            :message-styling="messageStyling"
            @scrollToTop="$emit('scrollToTop')"
            @remove="$emit('remove', $event)"
        >
            <template v-slot:user-avatar="scopedProps">
                <slot name="user-avatar" :user="scopedProps.user" :message="scopedProps.message"> </slot>
            </template>
            <template v-slot:text-message-body="scopedProps">
                <slot
                    name="text-message-body"
                    :message="scopedProps.message"
                    :messageText="scopedProps.messageText"
                    :messageColors="scopedProps.messageColors"
                    :me="scopedProps.me"
                >
                </slot>
            </template>
            <template v-slot:system-message-body="scopedProps">
                <slot name="system-message-body" :message="scopedProps.message"> </slot>
            </template>
            <template v-slot:text-message-toolbox="scopedProps">
                <slot name="text-message-toolbox" :message="scopedProps.message" :me="scopedProps.me"> </slot>
            </template>
        </MessageList>
        <UserInput
            v-if="!showUserList"
            :show-emoji="showEmoji"
            :on-submit="onUserInputSubmit"
            :multi="isMulti()"
            :suggestions="getSuggestions()"
            :show-file="showFile"
            :placeholder="placeholder"
            :colors="colors"
            @onType="$emit('onType')"
            @edit="$emit('edit', $event)"
        />
    </div>
</template>

<script>
import Header from './Header.vue'
import MessageList from './MessageList.vue'
import UserInput from './UserInput.vue'
import UserList from './UserList.vue'

export default {
    components: {
        Header,
        MessageList,
        UserInput,
        UserList
    },
    props: {
        showEmoji: {
            type: Boolean,
            default: false
        },
        showCloseButton: {
            type: Boolean,
            default: true
        },
        showFile: {
            type: Boolean,
            default: false
        },
        showHeader: {
            type: Boolean,
            default: true
        },
        participants: {
            type: Array,
            required: true
        },
        title: {
            type: String,
            required: true
        },
        titleImageUrl: {
            type: String,
            default: ''
        },
        onUserInputSubmit: {
            type: Function,
            required: true
        },
        onClose: {
            type: Function,
            required: true
        },
        messageList: {
            type: Array,
            default: () => []
        },
        isOpen: {
            type: Boolean,
            default: () => false
        },
        placeholder: {
            type: String,
            default: 'Write a message...'
        },
        showTypingIndicator: {
            type: String,
            required: true
        },
        colors: {
            type: Object,
            required: true
        },
        alwaysScrollToBottom: {
            type: Boolean,
            required: true
        },
        messageStyling: {
            type: Boolean,
            required: true
        },
        disableUserListToggle: {
            type: Boolean,
            default: false
        },
        showEdition: {
            type: Boolean,
            required: true
        },
        showDeletion: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            showUserList: false
        }
    },
    computed: {
        messages() {
            let messages = this.messageList

            return messages
        }
    },
    methods: {
        handleUserListToggle(showUserList) {
            this.showUserList = showUserList
        },
        isMulti() {
            return !!(
                this.messages.length > 0 &&
                this.messages[this.messages.length - 1].suggestions &&
                this.messages[this.messages.length - 1].multi
            )
        },
        getSuggestions() {
            return this.messages.length > 0 ? this.messages[this.messages.length - 1].suggestions : []
        }
    }
}
</script>

<style scoped>
.sc-chat-window {
    width: 370px;
    height: calc(100% - 120px);
    max-height: 590px;
    position: fixed;
    right: 25px;
    bottom: 100px;
    box-sizing: border-box;
    box-shadow: 0px 7px 40px 2px rgba(148, 149, 150, 0.1);
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 10px;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    animation: fadeIn;
    animation-duration: 0.3s;
    animation-timing-function: ease-in-out;
}

.sc-chat-window.closed {
    opacity: 0;
    display: none;
    bottom: 90px;
}

@keyframes fadeIn {
    0% {
        display: none;
        opacity: 0;
    }

    100% {
        display: flex;
        opacity: 1;
    }
}

.sc-message--me {
    text-align: right;
}
.sc-message--them {
    text-align: left;
}

@media (max-width: 450px) {
    .sc-chat-window {
        width: 100%;
        height: 100%;
        max-height: 100%;
        right: 0px;
        bottom: 0px;
        border-radius: 0px;
    }
    .sc-chat-window {
        transition: 0.1s ease-in-out;
    }
    .sc-chat-window.closed {
        bottom: 0px;
    }
}
</style>
