import { render, staticRenderFns } from "./SystemMessage.vue?vue&type=template&id=97c0e076&scoped=true&"
import script from "./SystemMessage.vue?vue&type=script&lang=js&"
export * from "./SystemMessage.vue?vue&type=script&lang=js&"
import style0 from "./SystemMessage.vue?vue&type=style&index=0&id=97c0e076&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "97c0e076",
  null
  
)

export default component.exports