module.exports = {
    styles: ['cawibotweb.css'],
    header: {
        logo: { src: 'africapanel.png' },
        title: { text: null }
    },
    chat: {
        server: {
            name: 'AfricaPanel',
            avatar: 'cawibot.png',
        },
        user: {
            name: 'Vous',
            ///avatar: 'user.png',
        },
        placeholder: 'Votre message...',
    },
    colors: {
        header: {
            bg: '#189fbf',
            text: '#ffffff'
        },
        launcher: {
            bg: '#189fbf'
        },
        messageList: {
            bg: '#ffffff'
        },
        sentMessage: {
            bg: '#189fbf',
            text: '#ffffff'
        },
        receivedMessage: {
            bg: '#eaeaea',
            text: '#222222'
        },
        userInput: {
            bg: '#f4f7f9',
            text: '#565867'
        },
    },
    debug: {
        page: true
    }
}
