const axios = require('axios')

const Page = require('./page')

const SYSTEM_PREFIX = 'CW_' // prefix for system variables

/**
 * A questionnaire is a way to collect interviews.
 * It handles questions definition and routing scenario.
 * The project must define some settings: config, definitions, routing and quotas.
 */
class Questionnaire {
    /**
     * Instantiate a questionnaire.
     * @param {object} settings Questionnaire's settings
     */
    constructor(settings = {}) {
        this.config = settings.config || {}
        if (this.config.isClient) {
            axios.defaults.baseURL = process.env.VUE_APP_API_SERVER
        }

        this.types = settings.definitions.types
        this.questions = settings.definitions.questions
        this.routing = settings.routing || {}

        this.project = settings.project // for client-side usage
        this.connected = false // for client-side usage
        this.store = settings.store // for server-side usage

        /*
        this.quotas = settings.quotas || {}
        this.quotasCount = {}
        */

        this.iPage = 0 // index of the current page
        this.page = {} // description of the current page
        this.maxNbPages = Object.keys(this.questions).length /// TODO: calculate it from routing

        this.interview = {
            data: {},
            variables: {}, // non-data variables from DSC
            system: {
                gkid: null,
                start_date: new Date().toISOString(),
                finish_date: null,
                last_updated: new Date().toISOString(),
                page: 0,
                canceled: {}
            }
        }
    }

    ///////////////
    // INTERVIEW //
    ///////////////

    /**
     * Initialize an interview (i.e load existing or create empty).
     * @param {string} gkid Interview ID
     */
    async initInterview(gkid) {
        console.log('Questionnaire.initInterview', gkid)

        this.interview.system.gkid = gkid

        try {
            if (this.config.isClient) {
                // for client-side engine, get interview from local storage or call interview API
                const storageKey = this.project + '-' + gkid
                if (this.config.useLocalStorage !== false && localStorage.getItem(storageKey)) {
                    console.log('=> load interview from local storage')
                    try {
                        this.interview = JSON.parse(localStorage.getItem(storageKey))
                    } catch (e) {
                        // corrupted data - remove it
                        localStorage.removeItem(storageKey)
                    }
                } else if (this.connected) {
                    console.log('=> load interview from API')
                    if (!this.project) {
                        throw new Error('Missing project name for client-side engine')
                    }
                    const res = await axios.post(`/data/interview/${this.project}/${gkid}/init`, this.interview)
                    if (res.data) {
                        this.interview = res.data
                        if (this.config.useLocalStorage !== false) {
                            console.log('=> save interview in local storage')
                            localStorage.setItem(storageKey, JSON.stringify(this.interview))
                        }
                    }
                } else {
                    throw new Error('Unable to store interview')
                }
            } else {
                // for server-side engine, use project's store
                if (!this.store) {
                    throw new Error('Missing store for server-side engine')
                }
                this.interview = await this.store.init(gkid, this.interview)
            }
        } catch (err) {
            console.error('Questionnaire.initInterview:', err.message)
        }

        // interview now loaded, current page description must be updated
        return await this.getPage()
    }

    /**
     * Check responses validity, update current interview then get next page.
     * @param {object} responses Responses to the current page's questions
     */
    async nextPage(responses) {
        console.log('Questionnaire.nextPage')

        let hasError = false
        if (this.interview.system.stopDate) {
            console.log("TODO: send message to say it's finished ?")
        } else if (this.interview.system.start_date) {
            // check responses validity
            if (this.page.contents) {
                for (const content of this.page.contents) {
                    if (content.contentType !== 'question' || !content.varName) {
                        continue
                    }

                    const error = this._checkValidity(content, responses[content.varName])
                    if (error) {
                        // set content error
                        content.error = error
                        hasError = true
                    } else {
                        // unset content error and keep value
                        delete content.error
                        content.value = responses[content.varName]

                        // update interview
                        this.interview.data[content.varName] = responses[content.varName]
                        delete this.interview.system.canceled[content.varName] // clear previously canceled response, if any
                    }
                }
            }
        } else {
            console.error('Questionnaire.nextPage: start_date should be set')
        }

        // without error, store updated interview and get next page
        if (!hasError) {
            this.interview.system.page++
            this.interview.system.last_updated = new Date().toISOString()

            await this._saveInterview()

            // get next page
            const nextPage = await this.getPage()
            return nextPage
        }

        // otherwise, return current page
        return this.page
    }

    /**
     * Cancel previous responses in current interview then get previous page.
     */
    async prevPage() {
        console.log('Questionnaire.prevPage')
        // update interview
        if (this.interview.data) {
            // cancel responses
            if (this.page.contents) {
                for (const content of this.page.contents) {
                    if (content.contentType !== 'question' || !content.varName) {
                        continue
                    }
                    if (this.interview.data[content.varName] != null) {
                        this.interview.system.canceled[content.varName] = this.interview.data[content.varName] // keep it for further pre-fill if needed
                    }
                    delete this.interview.data[content.varName]
                }
            }

            // update page number
            this.interview.system.page--
            if (this.interview.system.page < 0) {
                this.interview.system.page = 0
            }

            this.interview.system.last_updated = new Date().toISOString()
        }

        // store updated interview
        await this._saveInterview()

        // get previous page
        const prevPage = await this.getPage()
        return prevPage
    }

    /**
     * Save the interview.
     *
     * @param {boolean} sw Flag to add a service worker
     */
    async _saveInterview(sw = false) {
        try {
            if (this.config.isClient) {
                // for client-side engine, save interview in local storage and through interview API
                if (this.config.useLocalStorage !== false) {
                    console.log('=> update interview in local storage')
                    const storageKey = this.project + '-' + this.interview.system.gkid
                    localStorage.setItem(storageKey, JSON.stringify(this.interview))
                }

                if (this.connected || sw) {
                    console.log('=> save interview through API')
                    if (!this.project) {
                        throw new Error('Missing project name for client-side engine')
                    }
                    await axios.post(`/data/interview/${this.project}/${this.interview.system.gkid}`, this.interview)
                }
            } else {
                // for server-side engine, use store directly
                if (!this.store) {
                    throw new Error('Missing store for server-side engine')
                }
                await this.store.save(this.interview.system.gkid, this.interview)
            }
        } catch (err) {
            console.error('Questionnaire._saveInterview:', err.message)
        }
    }

    /////////////
    // ROUTING //
    /////////////

    /**
     * Get questionnaire's current page, according to current interview and routing.
     */
    async getPage() {
        console.log('Questionnaire.getPage')
        this.iPage = 0
        this.page = {}

        // run questionnaire's routing
        try {
            if (this.config.console) {
                console.group()
            }
            await this._parseRouting(this.routing)
            if (this.config.console) {
                console.groupEnd()
            }
        } catch (e) {
            // "STOP" is an expected exception
            if (e.message !== 'STOP') {
                console.error(e)
            }
            if (this.config.console) {
                console.groupEnd()
            }
        }

        // amend page content depending on interview data
        if (!!this.page.contents) {
            for (const content of this.page.contents) {
                if (content.contentType === 'question') {
                    if (!!this.interview.data[content.varName]) {
                        // load response, if any
                        content.value = this.interview.data[content.varName]
                    } else if (!!this.interview.system.canceled[content.varName]) {
                        // otherwise try to load canceled response
                        content.value = this.interview.system.canceled[content.varName]
                    }
                }

                // normalize structure to simplify client-side rules
                if (!content.rules) {
                    content.rules = {}
                }
                if (!content.display) {
                    content.display = {}
                }
            }
        }

        // return page description
        return this.page
    }

    /**
     * Parse routing statements and execute them, according to interview data.
     * Update current page object.
     * @param {array} routing Routing statements list
     */
    async _parseRouting(routing) {
        ///console.log('Questionnaire._parseRouting')
        for (const statement of routing) {
            ///console.log(statement)
            if (statement.keyword === 'function') {
                switch (statement.name) {
                    case 'newpage': {
                        let options = {}
                        if (statement.params) {
                            for (let option of statement.params.split(',').map(p => p.trim())) {
                                const kv = option.split('=').map(i => i.trim())
                                options[kv[0]] = kv[1]
                            }
                        }
                        this.newPage(options)
                        break
                    }
                    case 'endpage': {
                        this.endPage()
                        break
                    }
                    case 'ask': {
                        const varName = statement.params[0]
                        this.ask(varName)
                        break
                    }
                    case 'message': {
                        const msg = statement.params
                        this.message(msg)
                        break
                    }
                    default:
                        console.log(`WARNING: unknown routing function "${statement.name}"`)
                }
            } else if (statement.keyword === 'instruction') {
                switch (statement.type) {
                    case 'ifthen': {
                        let doThen = false
                        const op = statement.cond.op
                        if (op === 'eq') {
                            const expr = statement.cond.exprs.map(e => {
                                if (e.var) {
                                    if (e.var.startsWith(SYSTEM_PREFIX)) {
                                        return this.interview.system[e.var.replace(SYSTEM_PREFIX, '')]
                                    }
                                    return this.interview.data[e.var] || this.interview.variables[e.var]
                                } else if (!!e.const) {
                                    return e.const
                                } else {
                                    console.log('WARNING: unexpected expression for "eq" operator', e)
                                }
                            })
                            if (expr[0] === expr[1]) {
                                doThen = true
                            }
                        } else if (op === 'in') {
                            /*
                            const varName = statement.instruction.bool.expr.expr.xqualname.qualname.xname.question.id
                            let elements = this._forceArray(statement.instruction.bool.expr.set.dictionary.element)
                            const testedValues = elements.map((e) => (e.tag.type === 'integer' ? parseInt(e.tag._) : e.tag._))
                            let selectedValues = this._forceArray(this.interview.data[varName])
                            if (selectedValues.filter((v) => testedValues.includes(v)).length > 0) {
                                doThen = true
                            }
                            */
                        } else if (op === 'match') {
                            const expr = statement.cond.exprs.map(e => {
                                if (e.var) {
                                    if (e.var.startsWith(SYSTEM_PREFIX)) {
                                        return this.interview.system[e.var.replace(SYSTEM_PREFIX, '')]
                                    }
                                    return this.interview.data[e.var] || this.interview.variables[e.var]
                                } else if (!!e.const) {
                                    return e.const
                                } else {
                                    console.log('WARNING: unexpected expression for "match" operator', e)
                                }
                            })
                            const regex = new RegExp(expr[1])
                            if (regex.test(expr[0])) {
                                doThen = true
                            }
                        } else {
                            console.log(`WARNING: unknown instruction operator "${op}"`)
                        }

                        if (doThen) {
                            const subrouting = this._forceArray(statement.then)
                            await this._parseRouting(subrouting)
                        } else if (statement.else) {
                            const subrouting = this._forceArray(statement.else)
                            await this._parseRouting(subrouting)
                        }
                        break
                    }
                    case 'assignment': {
                        const varname = statement.var
                        const value = statement.val
                        const entry = this.questions[varname] ? 'data' : 'variables'
                        if (this.interview[entry][varname] === undefined) {
                            console.log('ASSIGN', varname, value)
                            this.interview[entry][varname] = value
                        }
                        break
                    }
                    case 'stop': {
                        const bye = statement.params
                        await this.stop(bye)
                        break
                    }
                    default:
                        console.log(`WARNING: unknown routing instruction "${statement.type}"`)
                }
            }
        }
    }

    /**
     * Check response validity according to question's definition rules.
     * @param {string} q Question definition
     * @param {string|number} val Response value
     */
    _checkValidity(q, val) {
        console.log('Questionnaire._checkValidity', q.varName)
        let error = null
        if (val == null || val === '' || (q.display.stars && val === 0)) {
            if (q.rules.required) {
                error = 'message.required'
            }
        } else {
            const isMulti = q.rules.maxChoices > 1
            if (
                q.type === 'choice' &&
                ((isMulti && val.filter(v => !q.modalities.map(m => m.code).includes(v)).length) || // multi-choice
                    (!isMulti && !q.modalities.map(m => m.code).includes(val))) // single choice
            ) {
                error = 'message.unexpected_value'
            } else if (q.type === 'numeric' && ((q.rules.range.min != null && val < q.rules.range.min) || (q.rules.range.max != null && val > q.rules.range.max))) {
                error = 'message.unexpected_value'
            }
            /// TODO: handle more checks
        }
        return error
    }

    /////
    // Functions below should be used inside the _parseRouting() function.
    /////

    /**
     * Define a page start in questionnaire's routing.
     * @param {object} options Page options
     */
    newPage(options = {}) {
        if (this.iPage === this.interview.system.page) {
            if (this.config.console) {
                console.info('%cNEW PAGE', 'color: orange', this.iPage)
            }

            this.page = new Page(this.iPage)

            // id
            if (options.id) {
                this.page.id = options.id
            }

            // progress
            if (options.progress) {
                // explicit page progress
                this.page.progress = options.progress
            } else {
                // progress based on estimated max nb pages
                this.page.progress = parseInt((this.iPage / this.maxNbPages) * 100)
            }
        }
    }

    /**
     * Define a page end in questionnaire's routing.
     */
    endPage() {
        if (this.iPage === this.interview.system.page) {
            if (this.config.console) {
                console.info('%cEND PAGE', 'color: orange', this.iPage)
            }
            throw new RouteException('STOP')
        }
        this.iPage++
    }

    /**
     * Ask a question in a page of questionnaire's routing, adding it to current page's contents.
     * This function should be used between a call to newPage() and a call to endPage().
     * @param {string} varName Question's name (identifier)
     */
    ask(varName) {
        if (this.iPage === this.interview.system.page) {
            if (this.config.console) {
                console.info('%cASK', 'color: orange', varName)
            }

            // extend question with types definitions
            const question = this._extendQuestion(this.questions[varName])
            question.varName = varName

            // explode dimensions
            const contents = this._explodeDimensions(question)

            // add contents
            for (let content of contents) {
                this.page.addContent({
                    contentType: 'question',
                    ...content
                })
            }
        }
    }

    /**
     * Extend question structure with types definitions.
     * @param {object} question Question's definition structure
     * @returns {object} Extended questions structure
     */
    _extendQuestion(question) {
        // data type and modalities
        if (question.dataType && typeof question.dataType === 'string' && this.types[question.dataType]) {
            var typeName = question.dataType
            question.dataType = this.types[typeName].dataType
            question.modalities = this.types[typeName].elements
        }

        // range
        if (question.rules && question.rules.range && typeof question.rules.range === 'string' && this.types[question.rules.range]) {
            question.rules.range = this.types[question.rules.range]
        }

        // dimensions
        if (question.dimensions) {
            question.dimensions = question.dimensions.map(d => {
                if (typeof d === 'string' && this.types[d]) {
                    return this.types[d].elements
                }
                return d
            })
        }

        return question
    }

    /**
     * Explode single question structure with dimensions into several questions structures.
     * @param {object} question Question's definition structure
     * @returns {array} Exploded questions structures
     */
    _explodeDimensions(question) {
        if (question.dimensions) {
            const f = (a, b) => [].concat(...a.map(d => b.map(e => [].concat(d, e))))
            const cartesianProduct = (a, b, ...c) => (b ? cartesianProduct(f(a, b), ...c) : a)
            const combinedDimensions = cartesianProduct(...question.dimensions)
            const flattenQuestions = combinedDimensions.map(dim => {
                dim = this._forceArray(dim)
                return {
                    varName: question.varName + '_' + dim.map(d => d.code).join('_'),
                    qRef: question.varName,
                    type: question.type,
                    label: question.label,
                    dimLabels: dim.map(d => d.label),
                    dataType: question.dataType,
                    modalities: question.modalities,
                    rules: question.rules,
                    display: question.display
                }
            })
            return flattenQuestions
        }
        return [question]
    }

    /**
     * Print a message in a page of questionnaire's routing, adding it to current page's contents.
     * This function should be used between a call to newPage() and a call to endPage().
     * @param {string} m Message
     */
    message(message) {
        if (this.iPage === this.interview.system.page) {
            if (this.config.console) {
                console.info('%cMESSAGE', 'color: orange', message)
            }
            this.page.addContent({ contentType: 'message', label: message })
        }
    }

    /**
     * Print a debug message in a page of questionnaire's routing, adding it to current page's contents.
     * This is a simple shortcut to the message() function with the debug option.
     * @param {string} message Message
     */
    debug(message) {
        this.message(message, { debug: true })
    }

    /**
     * Print a debug message in a page of questionnaire's routing, adding it to current page's contents.
     * This function should be used between a call to newPage() and a call to endPage().
     * @param {string} file HTML file to be included (from project's "components" directory)
     */
    include(file, options = {}) {
        if (this.iPage === this.interview.system.page) {
            if (this.config.console) {
                console.info('%cINCLUDE', 'color: orange', file)
            }
            this.page.addContent({ contentType: 'custom', file, options })
        }
    }

    /**
     * Get interview data for question in questionnaire's routing.
     * @param {string} varName Question's name (identifier)
     * @return {integer|string} d Interview's data for the question
     */
    get(varName) {
        const d = this.interview.data[varName]
        if (this.config.console) {
            console.info('%cGET', 'color: orange', varName, d)
        }
        return d
    }

    /**
     * Check if interview data for a multi-responses question contains a modality code.
     * @param {string} varName Question's name (identifier)
     * @param {string} code Modality code to search
     */
    has(varName, code) {
        return this.interview.data[varName] && this.interview.data[varName].includes(code)
    }

    /**
     * Check a quota.
     * @param {string} name Quota name
     * @returns {boolean}
     */
    checkQuota(name) {
        if (this.quotas[name]) {
            if (this.config.console) {
                console.info('%cCHECK QUOTA', 'color: orange', name)
            }
            for (const variable of Object.keys(this.quotas[name])) {
                for (const code of Object.keys(this.quotas[name][variable])) {
                    if (this.quotasCount[name][variable][code] >= this.quotas[name][variable][code]) {
                        return true
                    }
                }
            }
        }
        return false
    }

    /**
     * Check all quotas.
     */
    checkAllQuotas() {
        /// TODO
        return true
    }

    /**
     * End the interview.
     */
    async stop(bye) {
        if (this.config.console) {
            console.info('%cSTOP', 'color: orange', bye)
        }

        // get bye component - TODO: handle redirect if bye is an URL ?
        let byeContent = { contentType: 'message', label: "Merci d'avoir participé !" } // default
        try {
            const byeFile = require(`../../survey/project/includes/${bye}.vue`)
            if (byeFile) {
                byeContent = { contentType: 'custom', file: bye }
            }
        } catch (err) {
            console.error('Questionnaire.stop:', err.message)
        }

        // update interview
        this.interview.system.finish_date = new Date().toISOString()
        await this._saveInterview(true)

        // update page
        this.page = {
            isLast: true,
            progress: 100,
            contents: [byeContent]
        }
        throw new RouteException('STOP')
    }

    ///////////
    // TOOLS //
    ///////////

    /**
     * Force a variable to be an array if not.
     * @param {} v Variable or array
     * @return {array} Array
     */
    _forceArray(v) {
        if (v === undefined || v === null) {
            return []
        }
        if (!v.length) {
            v = [v]
        }
        return v
    }
}

/*
 * Handle expected exceptions in questionnaire's routing.
 * - STOP => allows to exit the route() function on the endPage() call of the interview's current page
 */
class RouteException {
    constructor(msg) {
        this.message = msg
    }
}

module.exports = Questionnaire
